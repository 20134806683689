import React from 'react';

const PDFUpdateView = React.lazy(() => import('views/Portal/Updates/View'));
const PDFExitStatementView = React.lazy(
  () => import('views/Portal/ExitStatement/ViewExitStatement'),
);
const PDFUpdateDownload = React.lazy(
  () => import('views/Portal/Updates/Download'),
);
const PDFExitStatementDownload = React.lazy(
  () => import('views/Portal/ExitStatement/Download'),
);
const AdviserEntryPage = React.lazy(() => import('views/Adviser/EntryPage'));

const customRoutes = [
  {
    exact: true,
    path: '/updates/:reportingId/:investmentId?',
    name: 'PDFUpdatesView',
    component: PDFUpdateView,
  },
  {
    exact: true,
    path: '/updates/download/:reportingId',
    name: 'PDFUpdatesDownload',
    component: PDFUpdateDownload,
  },
  {
    exact: true,
    path: '/exit-statement/:exitStatementId',
    name: 'PDFExitStatementView',
    component: PDFExitStatementView,
  },
  {
    exact: true,
    path: '/exit-statement/download/:exitStatementId',
    name: 'PDFExitStatementDownload',
    component: PDFExitStatementDownload,
  },
  {
    exact: true,
    path: '/adviser-entry',
    name: 'AdviserEntryPage',
    component: AdviserEntryPage,
  },
];

export default customRoutes;
